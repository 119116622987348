import gql from 'graphql-tag';
import itemsFragmentQuery from './itemsFragmentQuery';

export function searchTotalCountQuery(filter) {
  const filterString = JSON.stringify(filter)
    .replace(/[^\w\s]/g, '')
    .replace(/\s/g, '');
  const name = `SearchTotalCountQuery_${filterString}`;
  return gql`
    query ${name}($filter: ItemFilter) {
      itemsConnection(filter: $filter) {
        totalCount
      }
    }
  `;
}

export function searchItemsQuery({ first, sort, filter }) {
  const filterString = JSON.stringify(filter)
    .replace(/[^\w\s]/g, '')
    .replace(/\s/g, '');
  const name = `SearchItemsQuery_${first}_${sort}_${filterString}`;
  return gql`
    query ${name}(
      $first: Int
      $filter: ItemFilter
      $sort: ItemConnectionSort!
      $cursor: String
    ) {
      itemsConnection(
        first: $first
        after: $cursor
        filter: $filter
        sort: $sort
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...itemFields
          }
        }
      }
    }
    ${itemsFragmentQuery}
  `;
}

export function moreSearchItemsQuery({ first, cursor, sort, filter }) {
  const filterString = JSON.stringify(filter)
    .replace(/[^\w\s]/g, '')
    .replace(/\s/g, '');
  const name = `MoreSearchItemsQuery_${first}_${cursor}_${sort}_${filterString}`;
  return gql`
    query ${name}(
      $first: Int
      $filter: ItemFilter
      $sort: ItemConnectionSort!
      $cursor: String
    ) {
      itemsConnection(
        first: $first
        after: $cursor
        filter: $filter
        sort: $sort
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...itemFields
          }
        }
      }
    }
    ${itemsFragmentQuery}
  `;
}
