import gql from 'graphql-tag';

export const deleteBookmarkQuery = gql`
  mutation DeleteBookmark($itemId: ID!) {
    deleteBookmark(itemId: $itemId) {
      bookmark {
        itemId
      }
      errors {
        message
        path
      }
    }
  }
`;

export const createBookmarkQuery = gql`
  mutation CreateBookmark($itemId: ID!) {
    createBookmark(itemId: $itemId) {
      bookmark {
        itemId
      }
      errors {
        message
        path
      }
    }
  }
`;

export const deleteCompanyQuery = gql`
  mutation LogOut {
    logOut {
      profile {
        ... on Company {
          id
        }
      }
      errors {
        message
      }
    }
  }
`;
