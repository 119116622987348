import gql from 'graphql-tag';

export function itemQuery(id) {
  const name = `ItemQuery_${id}`;
  return gql`
    query ${name}($id: ID!) {
      item(id: $id) {
        id
      }
    }
  `;
}

const temp = 'just for eslint';

export default temp;
