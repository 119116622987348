import gql from 'graphql-tag';
import genericFields from './genericFields';

const bookmarksFragmentQuery = gql`
  fragment bookmarksItemFields on Item {
    ${genericFields}
    photos(limit: 4) {
      thumbnail
      f460x345
      large
    }
  }
`;

export const bookmarksAllQuery = gql`
  query BookmarksAllQuery {
    bookmarks {
      item {
        ...bookmarksItemFields
      }
    }
  }
  ${bookmarksFragmentQuery}
`;

const temp = 'just for eslint';

export default temp;
