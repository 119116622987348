import gql from 'graphql-tag';

export const citiesListQuery = gql`
  query CitiesListQuery {
    cities(filter: { scope: ALL }, limit: 70) {
      id
      name
      slug
    }
  }
`;

export const citiesPopularListQuery = gql`
  query CitiesPopularListQuery {
    cities(filter: { scope: POPULAR }, limit: 5) {
      id
      name
      slug
    }
  }
`;
