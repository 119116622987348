import gql from 'graphql-tag';

export const businessCentersQuery = gql`
  query businessCentersQuery {
    businessCenters {
      id
      address
      totalArea {
        minimum
        maximum
      }
      coverImage {
        f150x158
      }
      grade
      metroName
      name
      price {
        currency
        perUnit
        total {
          minimum
          maximum
        }
      }
      shortAddress
      updatedAt
      city {
        id
        name
      }
      path
    }
  }
`;

export default businessCentersQuery;
