import gql from 'graphql-tag';

export const companyNameQuery = gql`
  query CompanyNameQuery {
    profile {
      ... on Company {
        name
        id
      }
    }
  }
`;

const temp = 'just for eslint';

export default temp;
