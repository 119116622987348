import gql from 'graphql-tag';

export function seoQuery(path) {
  const name = `SeoQuery_2_${path}`.replace(/[^_0-9A-Za-z]/g, ''); // http://spec.graphql.org/June2018/#sec-Names

  return gql`
    query ${name}($path: Path!){
      seo(path: $path) {
        id
        title
        description
        h1
      }
    }
  `;
}

const temp = 'just for eslint';

export default temp;
