import gql from 'graphql-tag';

export const keywordsQuery = gql`
  query KeywordsQuery {
    keywords(filter: { path: "/" }) {
      id
      term
      url
    }
  }
`;

const temp = 'just for eslint';

export default temp;
