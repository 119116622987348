import gql from 'graphql-tag';

export const categoriesQuery = gql`
  query CategoriesQuery {
    categories {
      id
      name
      pluralName
      slug
      children {
        id
        name
        pluralName
        slug
      }
    }
  }
`;

const temp = 'just for eslint';

export default temp;
