import gql from 'graphql-tag';

export const residencesRowQuery = gql`
  query ResidencesRowQuery {
    residences(limit: 4, sort: RANDOM) {
      id
      name
      price {
        perUnit
        total {
          minimum
          maximum
        }
        currency
      }
      address
      shortAddress
      deadline
      coverUrl
      path
    }
  }
`;

export const residencesQuery = gql`
  query ResidencesQuery {
    residences(sort: RANDOM) {
      id
      name
      city {
        name
      }
      price {
        perUnit
        total {
          minimum
          maximum
        }
        currency
      }
      address
      deadline
      coverUrl
      path
      metroName
      companyName
      updatedAt
    }
  }
`;
