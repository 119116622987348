import gql from 'graphql-tag';

export function locationsQuery({ cityId, scope, limit }) {
  const name = `LocationsQuery_${cityId}_${scope}_${limit}`;
  return gql`
    query ${name}($cityId: ID, $scope: LocationScopeEnum!, $limit: Int!) {
      locations(filter: { scope: $scope, cityId: $cityId }, limit: $limit) {
        id
        name
        fullName
        locationGroupId
        slug
      }
    }
  `;
}

const temp = 'just for eslint';

export default temp;
